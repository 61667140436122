<template>
  <div class="overview-builder builder flex-container">
    <div class="cards-container custom-scrollbar">

    <div v-if="component">
      <OpenerCard :component="component"></OpenerCard>
    </div>
      <div class="m-10 lesson-wrappershadow lesson-hotspot-bg" v-if="component">
        <div class="text-center">
          <span class="legend">Hot-Spots</span>
        </div>
        <div>
          <!-- <pre>{{component.hotspots}}</pre> -->
          <div>
          <HotSpot
            v-for="(component, i) in components"
            :key="i"
            :component="component"
          ></HotSpot>
          </div>
        </div>
      </div>
      <div class="m-10 lesson-wrappershadow story-lesson-bg" v-if="component">
        <div class="text-center">
          <span class="legend">Bill Bennett Story</span>
        </div>
        <StoryCard v-if="component"
          :component="storycomponentData"
        ></StoryCard>
      </div>
    </div>
    <properties-panel :save-component="saveOverview" />
  </div>
</template>

<script>
import PropertiesPanel from "@/components/BackOffice/PropertiesPanel.vue";
import BuilderMixin from "@/views/BackOffice/BuilderMixin.js";
import OpenerCard from "@/components/BackOffice/OpenerBuilder/OpenerCard.vue";
import StoryCard from "@/components/BackOffice/OpenerBuilder/StoryCard.vue";
import HotSpot from "@/components/BackOffice/OpenerBuilder/HotSpot.vue";
export default {
  name: "OpenerBuilder",
  mixins: [BuilderMixin],
  components: {
    PropertiesPanel,
    OpenerCard,
    StoryCard,
    HotSpot,
  },
  data() {
    return {
      openerCardData: {
        componentType: "OpenerCard",
        audioOverview: null,
        unitDescription: {
          en: "Description",
          es: "Description-ES",
          id: 2026748,
        },
        errors: [],
        id: 10404,
        imageId: 190919,
        imageName: "92051c91-db9c-420e-b16b-be584273f0d9.jpg",
        unitTitle: {
          en: "Unit title",
          es: "Overview-ES",
          id: 2026746,
        },
        number: 0,
        secondaryImageId: 190917,
        secondaryImageName: "85edf508-7f08-4ebe-990d-7610f525f088.jpg",
        setters: [
          {
            model: "name",
            name: "title-setter",
            placeholder: "Lesson Title",
          },
          {
            model: "imageId",
            name: "image-upload",
            text: "addImageText",
          },
          {
            model: "audioOverview",
            name: "audio-upload",
          },
        //    {
        //     name: "background-setter",
        //     model: "backgroundColor"
        // },
        {
            name: "background-setter",
            model: "titleBackgroundColor",
            propertyName: "overviewTitleBackground"
        },
        {
                "name": "font-color-setter",
                "model": "titleColor"
            },
        
        ],
      },

      storyCardData: [
        {
          display: true,
          componentType: "StoryCard",
          audioOverview: null,
          description: {
            en: "Description",
            es: "Description-ES",
            id: 2026748,
          },
          errors: [],
          essentialQuestionOverview: {
            audio: null,
            audioId: null,
            id: 213488,
            question: {
              en: "",
              es: "",
              id: 2026815,
            },
            questionId: 2026815,
          },
          id: 10403,
          imageId: 190919,
          imageName: "92051c91-db9c-420e-b16b-be584273f0d9.jpg",
          name: {
            en: "title",
            es: "title-ES",
            id: 2026746,
          },
          number: 0,
          secondaryImageId: 190917,
          secondaryImageName: "85edf508-7f08-4ebe-990d-7610f525f088.jpg",
          setters: [
            {
              model: "imageId",
              name: "image-upload",
              text: "addImageText",
            },
            {
              model: "audioOverview",
              name: "audio-upload",
            },
          ],
        },
      ],

      hotspotData: [
        {
         
          componentType: "HotSpot",
          audioOverview: null,
          description: {
            en: "Description",
            es: "Description-ES",
            id: 2026748,
          },

          errors: [],
          essentialQuestionOverview: {
            audio: null,
            audioId: null,
            id: 213488,
            question: {
              en: "",
              es: "",
              id: 2026815,
            },
            questionId: 2026815,
          },
          id: 10404,
          imageId: 190919,
          imageName: "92051c91-db9c-420e-b16b-be584273f0d9.jpg",
          name: {
            en: "lesson title",
            es: "Overview-ES",
            id: 2026746,
          },
          number: 0,
          secondaryImageId: 190917,
          secondaryImageName: "85edf508-7f08-4ebe-990d-7610f525f088.jpg",
          setters: [
            {
              model: "imageId",
              name: "image-upload",
              text: "addImageText",
            },
            {
              model: "audioOverview",
              name: "audio-upload",
            },
          ],
        },
        {

          componentType: "HotSpot",
          audioOverview: null,
          description: {
            en: "Description",
            es: "Description-ES",
            id: 2026748,
          },

          errors: [],
          essentialQuestionOverview: {
            audio: null,
            audioId: null,
            id: 213488,
            question: {
              en: "",
              es: "",
              id: 2026815,
            },
            questionId: 2026815,
          },
          id: 10404,
          imageId: 190919,
          imageName: "92051c91-db9c-420e-b16b-be584273f0d9.jpg",
          name: {
            en: "lesson title 2",
            es: "Overview-ES",
            id: 2026746,
          },
          number: 0,
          secondaryImageId: 190917,
          secondaryImageName: "85edf508-7f08-4ebe-990d-7610f525f088.jpg",
          setters: [
            {
              model: "imageId",
              name: "image-upload",
              text: "addImageText",
            },
            {
              model: "audioOverview",
              name: "audio-upload",
            },
          ],
          routePoints: [],
        },
        {
          componentType: "HotSpot",
          audioOverview: null,
          description: {
            en: "Description",
            es: "Description-ES",
            id: 2026748,
          },

          errors: [],
          essentialQuestionOverview: {
            audio: null,
            audioId: null,
            id: 213488,
            question: {
              en: "",
              es: "",
              id: 2026815,
            },
            questionId: 2026815,
          },
          id: 10404,
          imageId: 190919,
          imageName: "92051c91-db9c-420e-b16b-be584273f0d9.jpg",
          name: {
            en: "lesson title",
            es: "Overview-ES",
            id: 2026746,
          },
          number: 0,
          secondaryImageId: 190917,
          secondaryImageName: "85edf508-7f08-4ebe-990d-7610f525f088.jpg",
          setters: [
            {
              model: "imageId",
              name: "image-upload",
              text: "addImageText",
            },
            {
              model: "audioOverview",
              name: "audio-upload",
            },
          ],
          routePoints: [],
        },
      ],
    };
  },
  computed: {
    components(){
        return this.$store.state.openerComponents.hotspots;
    },
    component() {
      return this.$store.state.openerComponents;
    },  
    storycomponentData(){
      return this.$store.state.openerComponents.story;
    }
  },
  methods: {
    
   
    saveOverviewComponent(component) {
      // if (!this.validateComponentFields(component)) return;
      this.$store.dispatch("updateUnitOpener", component);
    },
    // addAlerts(component) {
    //   component.errors.forEach((error) => {
    //     let errorNotification = this.$store.state.notifications.find(
    //       (e) => e.message === error
    //     );
    //     if (!errorNotification) {
    //       this.$store.commit("addSnackBarNotification", {
    //         message: error,
    //         dismissable: true,
    //       });
    //     }
    //   });
    // },
    // validateComponentFields(component) {
    //   if (!component) return false;
    //   if (component.errors.length > 0) {
    //     this.addAlerts();
    //     return false;
    //   }
    //   return true;
    // },
    setComponentPropertyValue(component, value, propertyName){
            this.$store.commit('setBuilderComponentPropertyValue',
                    {component: component, propertyName: propertyName, value: value})
        },

    saveComponent(component){
      if(component.audio.id !=0){
        this.setComponentPropertyValue(
          component,
          component.audio.id,
          "audioId"
        );
      }
    },
    saveComponents(components){
      components.forEach(e => {
          if(e.audio.id !=0){
            this.setComponentPropertyValue(
             e,
             e.audio.id,
             "audioId"
        );
          }
      });
    },
    saveStoryComponent(storycomponentData){
       if(storycomponentData.audio.id !=0){
        this.setComponentPropertyValue(
          storycomponentData,
          storycomponentData.audio.id,
          "audioId"
        );
      }
    },
    saveOverview() {
      // console.log(this.component);
      this.saveComponent(this.component);
      // console.log(this.components);
      this.saveComponents(this.components);
      this.saveStoryComponent(this.storycomponentData);
       this.$store.dispatch("updateUnitOpener", this.component);
    },
    //On ESC set overview as current component to set Background
    updateCurrentComponent() {
      this.$store.commit(
        "updateCurrentBackOfficeComponent",
        this.$store.state.openerComponents
      );
    },
    keydown(e) {
      if (e.keyCode == 27) this.updateCurrentComponent();
    }
  },
  created() {
       this.$store.dispatch("loadUpdatedUnitOpenerData", this.$route.params.unitId)
      .then(() => {
        setTimeout(()=>{
          this.updateCurrentComponent(); 
        },2000);  
      });  
    this.$store.commit("updateSwitchButtonDisplayStatus", false);
    this.$store.commit("updateContentSectionState", true);

  },

  beforeCreate() {
  },

  mounted() {
     
    this.updateCurrentComponent();
    document.addEventListener("keydown", this.keydown);
  },
 
  destroyed() {
    document.removeEventListener("keydown", this.keydown);
  },
};
</script>
<style lang="less" scoped>
.text-center {
  text-align: center;
}
.m-10 {
  margin: 20px 10px;
}
.lesson-wrappershadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  padding: 10px 10px;
}
.overview-builder {
  margin-top: 104px;
  height: calc(100vh - 104px);
  .cards-container {
    max-height: calc(100vh - 104px);
    overflow-y: auto;
  }
  > span {
    align-self: center;
    margin: auto;
    height: 28px;
    width: auto;
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto";
    font-size: 19.64px;
    letter-spacing: 0.5px;
    line-height: 28px;
  }
}

.lesson-hotspot-bg{
    background: #ededed61;
}
.story-lesson-bg{
background: #ededed;
}
</style>