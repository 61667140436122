var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "hotspot-card",
      class: { "component-active": _vm.isActive },
      on: { click: _vm.updateCurrentComponent }
    },
    [
      _c(
        "div",
        { staticClass: "lesson-card flex-container" },
        [
          _c("image-upload", {
            attrs: { component: _vm.component, options: _vm.options }
          }),
          _c("div", { staticClass: "card-body flex-container" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.storyTitle,
                  expression: "storyTitle"
                }
              ],
              attrs: {
                maxlength: "60",
                type: "text",
                placeholder: _vm.$t("titlePlaceHolder")
              },
              domProps: { value: _vm.storyTitle },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.storyTitle = $event.target.value
                }
              }
            }),
            _c(
              "div",
              [
                _c("ckeditor", {
                  staticClass: "content custom-scrollbar",
                  attrs: {
                    editor: _vm.editor,
                    placeholder: _vm.$t("textPlaceHolder"),
                    config: _vm.editorConfig
                  },
                  model: {
                    value: _vm.storyDescription,
                    callback: function($$v) {
                      _vm.storyDescription = $$v
                    },
                    expression: "storyDescription"
                  }
                })
              ],
              1
            )
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }