<template>
  <div class="unit-card-shadow " :class="{ 'component-active': isActive }">
    <div class="text-center">
      <div class="legend">Unit</div>
    </div>
    <div class="lesson-card opener-builder__rte flex-container" @click="updateCurrentComponent">
      <image-upload :component="component" :options="options" />
      <div
        class="card-body flex-container"
        
      >
      <!-- maxlength="60" -->
        <input
          type="text"
          v-model="title"
          :placeholder="$t('titlePlaceHolder')"
        />
        <div>
          <!-- <textarea
            :placeholder="$t('textPlaceHolder')"
            cols="30"
            rows="10"
            maxlength="250"
            class="content custom-scrollbar"
            v-model="description"
          ></textarea> -->
          <ckeditor
          :editor="editor"
          :placeholder="$t('textPlaceHolder')"
          :config="editorConfig"
            class="content custom-scrollbar"
            v-model="description"
        ></ckeditor>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ImageUpload from "@/components/BackOffice/ComponentSetters/ImageUpload.vue";
import BackOfficeComponentMixin from "@/components/BackOffice/Mixins/BackOfficeComponentMixin.js";

export default {
  name: "OpenerCard",
  mixins: [BackOfficeComponentMixin],
  components: {
    "image-upload": ImageUpload,
  },
  computed: {
    title: {
      get() {
        return this.component.title[this.locale];

      },
      set(value) {
        return this.setComponentPropertyValue(value, "title", this.locale);
      },
    },
    description: {
      get() {
        return this.component.description[this.locale] || ""; 
      },
      set(value) {
        return this.setComponentPropertyValue(
          value,
          "description",
          this.locale
        );
      },
    },
    options() {
      return { name: "image-details", text: "addImageText", model: "imageId" };
    },
  },
  
};
</script>

<style lang="less">
.margin-textarea {
  padding: 15px;
}
.lesson-card {
  cursor: pointer;
  .image-upload {
    height: 144px;
    width: 224px;
    background-color: #efefef;
    margin: auto;
    flex-direction: column;
    :global(.delete-icon) {
      order: 0;
      margin-top: 8px;
      height: 16px;
      max-width: 16px;
      margin-left: auto;
    }

    :global(.image) {
      order: 1;
      height: 64px;
      width: 80px;
      max-height: 64px;
      max-width: 80px;
      margin: 11px auto;
    }
    :global(span) {
      order: 2;
      margin: -3px auto;
      text-align: center;
    }
  }
  .card-body.flex-container {
    flex-direction: column;
  }
  .card-body {
    &.component-active {
      background-color: #ffffff;
      box-shadow: var(--secondary-shadow);
    }

    margin-right: 10px;
    min-height: 306px;
    width: 464px;
    border-radius: 4px;
    background-color: #f6f7fa;
    box-shadow: var(--primary-shadow);
    margin-top: 16px;
    margin-left: 16px;

    > span {
      margin-right: 16px;
      margin-left: 16px;
      margin-top: 26px;
    }

    input {
      min-width: 432px;
      max-width: 406px;
      height: 40px;
      resize: none;
      color: rgba(0, 0, 0, 0.6);
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      font-style: italic;
      letter-spacing: 0.4px;
      line-height: 16px;
      margin-top: 8px;
      margin-left: 16px;
      padding-left: 16px;
    }
    .ck.ck-editor {
      margin-left: 16px;
      margin-top: 8px;
      width: 432px;
      .ck-content {
        height: 96px;
      }
    }
  }
}
.text-center {
  text-align: center;
}
.lesson-card .card-body {
  background: none;
  box-shadow: none;
}
.unit-card-shadow {
  // background-color: #f6f7fa;
  border: 1px solid #ededed;
  margin: 10px;
  padding-top: 20px;
}
.opener-builder__rte{
  height: 224px;
}
</style>