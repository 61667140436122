var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "unit-card-shadow ",
      class: { "component-active": _vm.isActive }
    },
    [
      _vm._m(0),
      _c(
        "div",
        {
          staticClass: "lesson-card opener-builder__rte flex-container",
          on: { click: _vm.updateCurrentComponent }
        },
        [
          _c("image-upload", {
            attrs: { component: _vm.component, options: _vm.options }
          }),
          _c("div", { staticClass: "card-body flex-container" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.title,
                  expression: "title"
                }
              ],
              attrs: { type: "text", placeholder: _vm.$t("titlePlaceHolder") },
              domProps: { value: _vm.title },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.title = $event.target.value
                }
              }
            }),
            _c(
              "div",
              [
                _c("ckeditor", {
                  staticClass: "content custom-scrollbar",
                  attrs: {
                    editor: _vm.editor,
                    placeholder: _vm.$t("textPlaceHolder"),
                    config: _vm.editorConfig
                  },
                  model: {
                    value: _vm.description,
                    callback: function($$v) {
                      _vm.description = $$v
                    },
                    expression: "description"
                  }
                })
              ],
              1
            )
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center" }, [
      _c("div", { staticClass: "legend" }, [_vm._v("Unit")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }