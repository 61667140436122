var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "overview-builder builder flex-container" },
    [
      _c("div", { staticClass: "cards-container custom-scrollbar" }, [
        _vm.component
          ? _c(
              "div",
              [_c("OpenerCard", { attrs: { component: _vm.component } })],
              1
            )
          : _vm._e(),
        _vm.component
          ? _c(
              "div",
              { staticClass: "m-10 lesson-wrappershadow lesson-hotspot-bg" },
              [
                _vm._m(0),
                _c("div", [
                  _c(
                    "div",
                    _vm._l(_vm.components, function(component, i) {
                      return _c("HotSpot", {
                        key: i,
                        attrs: { component: component }
                      })
                    })
                  )
                ])
              ]
            )
          : _vm._e(),
        _vm.component
          ? _c(
              "div",
              { staticClass: "m-10 lesson-wrappershadow story-lesson-bg" },
              [
                _vm._m(1),
                _vm.component
                  ? _c("StoryCard", {
                      attrs: { component: _vm.storycomponentData }
                    })
                  : _vm._e()
              ],
              1
            )
          : _vm._e()
      ]),
      _c("properties-panel", { attrs: { "save-component": _vm.saveOverview } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center" }, [
      _c("span", { staticClass: "legend" }, [_vm._v("Hot-Spots")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center" }, [
      _c("span", { staticClass: "legend" }, [_vm._v("Bill Bennett Story")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }