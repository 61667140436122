<template>
  <div
    class="hotspot-card"
    :class="{ 'component-active': isActive }"
    @click="updateCurrentComponent"
  >
    

    <div class="lesson-card flex-container">
      <image-upload :component="component" :options="options" />
      <div class="card-body flex-container">
         <input
          maxlength="60"
          type="text"
          v-model="hotspotTitle"
          :placeholder="$t('titlePlaceHolder')"
        />
        <div class="">
          <!-- <textarea
            maxlength="250"
            :placeholder="$t('textPlaceHolder')"
            cols="30"
            rows="10"
            class="content custom-scrollbar"
            v-model="hotspotDescription"
          ></textarea> -->
          <ckeditor
          :editor="editor"
          :placeholder="$t('textPlaceHolder')"
          :config="editorConfig"
            class="content custom-scrollbar"
            v-model="hotspotDescription"
        ></ckeditor>
        </div>
      </div>
    </div>

    <!-- <hot-spot-point v-for="(component , index) in localHotspot" :component="component" :key="index"></hot-spot-point> -->
  </div>
</template>
<script>
import BackOfficeComponentMixin from "@/components/BackOffice/Mixins/BackOfficeComponentMixin.js";
import ImageUpload from "@/components/BackOffice/ComponentSetters/ImageUpload.vue";
import CheckBox from "@/components/BackOffice/ComponentSetters/CheckBox.vue";
// import HotSpotPoint from '@/components/BackOffice/ContentComponents/HotSpotPoint.vue';
// import Setters from '@/assets/BackOfficeAssets/component-setters.json';
// import Factory from "@/utils/ContentFactory.js";
export default {
  name: "HotSpot",
  mixins: [BackOfficeComponentMixin],
  components: {
    "image-upload": ImageUpload,
    CheckBox,
    //   'hot-spot-point':HotSpotPoint
  },
  props: {
    // returnLessonCheckedComponent: Number,
  },
  data() {
    return {
     
    };
  },
  computed: {
    hotspotDescription: {
      get() {
        return this.component.hotspotDescription[this.locale];
      },
      set(value) {
        return this.setComponentPropertyValue(value, "hotspotDescription", this.locale);
      },
    },
    hotspotTitle: {
      get() {
        return this.component.hotspotTitle[this.locale];
      },
      set(value){
        return this.setComponentPropertyValue(value, "hotspotTitle", this.locale)
      }
    },
    
  },
  methods: {
    checkIsSelected() {
      //  this.$emit("checkupdate", this.component)
      //  this.$store.commit('updateLessonCheckbox', this.component)
    },
  },
  mounted() {},
  created() {},
};
</script>
<style lang="less" scoped>
.lesson-name {
  font-weight: 600;
  margin-bottom: 10px;
}
.hotspot-card {
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 5px;
  margin-bottom: 10px;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #ededed;
}
.d-flex {
  display: flex;
  flex-wrap: wrap;
}
.text-center {
  text-align: center;
}
.addhotspot {
  margin-top: 20px;
}

.lesson-card .card-body {
  min-height: auto;
}
.lesson-card .card-body {
  box-shadow: 0px;
}
.component-active {
  box-shadow: 0px;
}
.text-left {
  text-align: left;
}
.custom-checkbox {
  width: 55px;
  min-height: 20px;
}
.inside-card-text{
  margin-top: 15px;
  padding-left: 15px;
}
</style>