<template>
  <div
    class="hotspot-card"
    :class="{ 'component-active': isActive }"
    @click="updateCurrentComponent"
  >
  

    <div class="lesson-card flex-container">
      <image-upload :component="component" :options="options" />
      <div class="card-body flex-container">
          <input
          maxlength="60"
          type="text"
          v-model="storyTitle"
          :placeholder="$t('titlePlaceHolder')"
        />
        <div >
        <!-- <textarea
          maxlength="250"
          :placeholder="$t('textPlaceHolder')"
          cols="30"
          rows="10"
          class="content custom-scrollbar"
          v-model="storyDescription"
        ></textarea> -->
        <ckeditor
          :editor="editor"
          :placeholder="$t('textPlaceHolder')"
          :config="editorConfig"
            class="content custom-scrollbar"
            v-model="storyDescription"
        ></ckeditor>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ImageUpload from "@/components/BackOffice/ComponentSetters/ImageUpload.vue";
import BackOfficeComponentMixin from "@/components/BackOffice/Mixins/BackOfficeComponentMixin.js";
import CheckBox from "@/components/BackOffice/ComponentSetters/CheckBox.vue";
export default {
  name: "StoryCard",
  mixins: [BackOfficeComponentMixin],
  components: {
    "image-upload": ImageUpload,
    CheckBox,
  },
  computed: {
    storyTitle:{
       get(){
          return this.component.storyTitle[this.locale];
       },
      set(value) {
        return this.setComponentPropertyValue(value, "storyTitle", this.locale);
      },
    },
    storyDescription: {
      get() {
        return this.component.storyDescription[this.locale];
      },
      set(value) {
        return this.setComponentPropertyValue(value, "storyDescription", this.locale);
      },
    },
    options() {
      return { name: "image-details", text: "addImageText", model: "imageId" };
    },
    display: {
      get() {
        return this.component.display;
      },
      set(value) {
        return this.setComponentPropertyValue(value, "display");
      },
    },
    setLimit() {
      if (this.returnStoryCheckedComponent >= 1 && this.display) {
        
        return false;
      }
      if (this.returnStoryCheckedComponent >= 1 && !this.display) {
        return true;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.text-center {
  text-align: center;
}
// .lesson-card .card-body.flex-container {
//   // flex-direction: row;
// }
.lesson-card .card-body {
  min-height: auto;
}
.hotspot-card {
  margin-top: 40px;
  margin-right: 10px;
  margin-left: 5px;
  margin-bottom: 50px;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #ededed;
  // box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);;
}
.lesson-card .card-body {
  box-shadow: 0px;
}
.text-left {
  text-align: left;
}
.custom-checkbox {
  width: 55px;
  min-height: 20px;
}
.inside-card-text{
  margin-top: 15px;
  padding-left: 15px;
}
// .component-active{
//     box-shadow: 0px;
// }
</style>